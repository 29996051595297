<template>
  <div class="flex flex-column" style="color:#000000">
    <h2 class="m-2 p-0">{{crossword.data?.settings?.topic}}</h2>
    <div id="phaser-container-print"></div>
    <div class="m-2">
      <h3 class="m-0"><b>HORIZONTAL</b></h3>
      <div v-for="w of itemGroups.h" :key="w.ix" class="flex flex-row">
        <div class="w-1rem"><sup>{{w.ix+1}}</sup></div><div class="ml-1">{{w.hint}}</div>
      </div>
      <h3 class="mt-3"><b>VERTIKAL</b></h3>
      <div v-for="w of itemGroups.v" :key="w.ix" class="flex flex-row">
        <div class="w-1rem"><sup>{{w.ix+1}}</sup></div><div class="ml-1">{{w.hint}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Game from "@/components/Game.vue";
import Phaser from "phaser";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'GamePrinter',
  extends: Game,
  props: {
    uuid: String
  },
  data() {
    return {
      ...Game.data.call(this),
      plainLayout: true,
      phaserRender: Phaser.CANVAS,
      phaserScaleMode: Phaser.Scale.CENTER_HORIZONTALLY,
      backgroundColor:"#ffffff",
      phaserContainerId:"phaser-container-print",
      itemGroups:{}
    }
  },
  methods:{
    ...Game.methods,
    async onMounted() {
      await Game.methods.onMounted.call(this);
      this.itemGroups = this.getItemGroups();
    },
    getItemGroups() {
      if (this.crossword.data?.config) {
        let words = [...this.crossword.data.config.words];
        words = words.map((w,ix)=>({...w,ix}));
        let h = words.filter(w=>w.orientation===0);
        let v = words.filter(w=>w.orientation===1);
        return {h,v};
      }
      return {};
    },
    onPhaserResize() {
      return null;
    },
  }
}
</script>