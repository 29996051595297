<template>
  <form class="flex-grow-1 flex flex-colum justify-content-center align-items-center bg-login" ref="loginForm" autocomplete="on" v-on:submit.prevent>
    <div :class="contentClasses">
      <div class="text-center mb-3">
        <div class="flex flex-row justify-content-center align-items-end mb-1">
          <div class="flex flex-row justify-content-center align-items-center mb-1">
            <div class="text-900 text-3xl font-medium flex align-items-end line-height-1" @click="goto()">Crosswords AI</div>
          </div>
        </div>
        <span class="font-medium line-height-3">Ein Konto anlegen</span>
      </div>

      <div>
        <label for="email" class="block text-900 font-medium mb-2">E-Mail</label>
        <InputText id="email" type="text" class="w-full mb-3" v-model="email" name="email" required />

        <label for="password0" class="block text-900 font-medium mb-2">Passwort</label>
        <InputText id="password0" type="password" class="w-full mb-3" v-model="p0" name="password0" required v-on:keyup.enter="register" />
        <label for="password1" class="block text-900 font-medium mb-2">Wiederholen</label>
        <InputText id="password1" type="password" class="w-full mb-3" v-model="p1" name="password1" required v-on:keyup.enter="register" />

        <div class="flex flex-row align-items-center justify-content-end mb-3">
          <label for="disclaimer"><span class="font-normal">Ich akzeptiere die</span> <a href="#" @click.prevent="displayDisclaimer=true" class="text-blue-500 no-underline">Nutzungsbedingungen</a></label>
          <Checkbox v-model="disclaimer" class="ml-2" :binary="true" />
        </div>

        <div class="flex align-items-center justify-content-between">
          <div class="flex flex-column text-sm">
            <a v-if="externalLinks" class="font-medium no-underline text-blue-500 cursor-pointer" href="#" @click.prevent="startOver">Sie haben schon ein Konto?</a>
          </div>
          <Button :disabled="!(this.email && this.p0 && this.p1 && this.disclaimer) || this.sending" label="Registrieren" :icon="senderIcon" class="flex-shrink-0" @click="register"></Button>
        </div>
      </div>
    </div>
    <Dialog v-model:visible="displayDisclaimer" :modal="true" :close-on-escape="true" :closable="true" :dismissable-mask="true" :draggable="false" style="max-width: 600px" :class="'disclaimer-dialog'">
      <template #header>
        <div class="flex flex-row align-items-center">
          Nutzungsbedingungen
        </div>
      </template>
      <div class="px-3">
        <p>Bitte beachten Sie, dass diese Anwendung Ihnen als öffentlicher Dienst zur Verfügung gestellt wird. Wir behalten uns das Recht vor, die Applikation jederzeit und ohne vorherige Ankündigung oder Benachrichtigung zurückzuziehen oder offline zu nehmen.</p>
        <p>Durch die Nutzung dieses Dienstes erklären Sie sich damit einverstanden, dass wir keine Verantwortung oder Haftung für den Verlust von Daten oder Informationen übernehmen, die während der Nutzung entstanden sind. Es obliegt Ihrer Verantwortung, regelmässig Sicherheitskopien Ihrer Daten anzufertigen und diese ausserhalb der Applikation aufzubewahren.</p>
      </div>
    </Dialog>
  </form>
</template>

<script>

import RegistrationView from "@/components/RegistrationView.vue";
import AuthGuestView from "@/components/AuthGuestView.vue";

export default {
  name: 'RegistrationGuestTransferView',
  extends: AuthGuestView,
  components: {
  },
  data() {
    return {
      ...RegistrationView.data.call(this)
    }
  },
  methods: {
    ...RegistrationView.methods,
    async registrationRequest({email,password}) {
      await this.authService.transferGuestUser({email,password});
    }
  }
}
</script>