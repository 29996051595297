import http from "./http-auth";

class GamePrinterService {
  constructor(apiName="print-game") {
    this.apiName=apiName;
  }
  get(uuid) {
    return http.get(`/${this.apiName}/${uuid}`,{responseType:"blob"});
  }
}
export default new GamePrinterService();
