<template>
  <div class="flex-grow-1 flex flex-column align-items-center">
    <div v-if="isGuest" class="p-2 mx-2 mt-2 mb-0 bg-white-alpha-80 relative flex flex-column">
      <div><i class="pi pi-user text-xl text-blue-500 relative" style="top: 3px;"></i> Logged in as {{profile.username}}</div>
      <a class="text-blue-500 inline-flex flex-row justify-content-end mt-1 no-underline" href="/register"><i class="pi pi-caret-right"></i> Register</a>
    </div>
    <div class="flex justify-content-center align-items-center flex-grow-1 flex-wrap">
    </div>
  </div>
</template>

<script>

import AuthGuestView from "@/components/AuthGuestView.vue";
import CrosswordsService from "@/crosswords-service";

export default {
  name: 'RandomGame',
  extends: AuthGuestView,
  data() {
    return {
      profile:{},
      isGuest:false
    }
  },
  async created() {
    this.profile = await this.deferred;
    // if we don't have a login/profile at this point, this means guest creation is disallowed on the server (so redirect to /login)
    if (!this.profile)
      this.$router.push("/login");
    this.isGuest = this.profile.username?.match(/^guest_/);
    try {
      let {data} = await CrosswordsService.getRandom();
      if (data.uuid)
        this.goto(`/game/${data.uuid}`);
    } catch (err) {
      const response = err.response?.data||{};
      this.$toast.add({severity:'error', summary: `Fehler ${response.statusCode}`, detail:`${response.message}`, life: 3000})
    }
  },
  methods:{
    goto(path="/") {
      this.$router.push(path);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
a {
  color: #42b983;
}
ol { counter-reset: item; padding:0; line-height: 1.75;
  display: flex; flex-direction: column }
ol>li { display: flex; flex-direction: row }
ol>li:before {
  content: counter(item) ".\00a0\00a0";
  counter-increment: item;
  display: inline-block;
}
</style>
