<template>
  <div :class="`flex-grow-1 flex flex-column align-items-center`">
    <div v-if="isGuest" class="p-2 mx-2 mt-2 mb-0 bg-white-alpha-80 relative flex flex-column">
      <div><i class="pi pi-user text-xl text-blue-500 relative" style="top: 3px;"></i> Logged in as {{profile.username}}</div>
      <a class="text-blue-500 inline-flex flex-row justify-content-end mt-1 no-underline" href="/register"><i class="pi pi-caret-right"></i> Register</a>
    </div>
    <div class="flex flex-row align-items-start justify-content-start flex-wrap w-full my-1">
      <div v-for="crossword of listCrosswords" :key="crossword.uuid" :class="'flex flex-column bg-white-alpha-80 mx-2 my-1 md:m-2 p-2 cursor-pointer w-full md:w-auto'+(crossword.gameFinished ? ' border-2 border-green-600' : ' border-1 border-gray-300')" style="line-height:125%; min-width:300px"
      @click="goto('/my/'+crossword.uuid)">
        <div><b>{{crossword.data?.settings?.topic||'Ohne Titel'}}</b></div>
        <div>
          <i v-if="crossword.gameFinished" class="pi pi-check text-green-600"/>
          <i v-else-if="crossword.gameStarted" class="pi pi-clock"/>
          {{crossword.gameProgress}} / {{crossword.gameTarget}}
        </div>
      </div>
    </div>
    <div class="w-full">
      <Button @click="goto('/create')" class="p-button-primary mx-2">
        <div class="flex flex-row align-items-center">
          <i class="pi pi-plus text-2xl"></i>
          <div class="flex flex-column align-items-start ml-2">
            <div>Neues</div>
            <small>Kreuzworträtsel</small>
          </div>
        </div>
      </Button>
    </div>
  </div>
</template>

<script>
import AuthGuestView from "@/components/AuthGuestView.vue";
import CrosswordsService from "@/crosswords-service";

export default {
  extends: AuthGuestView,
  name: 'LaunchpadView',
  props: {
    msg: String
  },
  data() {
    return {
      profile:{},
      isGuest:false,
      listCrosswords:[],
      loading:true
    }
  },
  async created() {
    this.profile = await this.deferred;
    // if we don't have a login/profile at this point, this means guest creation is disallowed on the server (so redirect to /login)
    if (!this.profile)
      this.$router.push("/login");
    this.isGuest = this.profile.username?.match(/^guest_/);
    await this.loadCrosswords();
    this.loading = false;
  },
  methods:{
    goto(path="/") {
      this.$router.push(path);
    },
    async loadCrosswords() {
      ({data:this.listCrosswords} = await CrosswordsService.index());
      this.listCrosswords.map(crossword=>{
        try {
          crossword.data = JSON.parse(crossword.data);
          crossword.saveState = JSON.parse(crossword.saveState||{})
          let listWords = (crossword.data.config?.words||[]).map(w=>w.chars);
          let claimedWords = Object.keys(crossword.saveState?.claimedWords||{}).filter(w=>listWords.indexOf(w)>-1);
          crossword.gameTarget = (crossword.data.config?.words||[]).length;
          crossword.gameStarted = claimedWords.length>0;
          crossword.gameProgress = claimedWords.length;
          crossword.gameFinished = crossword.gameTarget>0 && crossword.gameProgress>=crossword.gameTarget;
        } catch (err) {
          crossword.data = {};
        }
        return crossword;
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
a {
  color: #42b983;
}
ol { counter-reset: item; padding:0; line-height: 1.75;
  display: flex; flex-direction: column }
ol>li { display: flex; flex-direction: row }
ol>li:before {
  content: counter(item) ".\00a0\00a0";
  counter-increment: item;
  display: inline-block;
}
</style>
