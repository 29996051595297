<template>
  <div class="flex-grow-1 flex flex-column align-items-center">
    <div class="flex justify-content-center align-items-center flex-grow-1 flex-wrap">
    </div>
  </div>
</template>

<script>

import AuthGuestView from "@/components/AuthGuestView.vue";
import CrosswordsService from "@/crosswords-service";

export default {
  name: 'CreateCrossword',
  extends: AuthGuestView,
  props: {
    msg: String
  },
  data() {
    return {
      profile:{},
      isGuest:false
    }
  },
  async created() {
    this.profile = await this.deferred;
    // if we don't have a login/profile at this point, this means guest creation is disallowed on the server (so redirect to /login)
    if (!this.profile)
      this.$router.push("/login");
    this.isGuest = this.profile.username?.match(/^guest_/);
    try {
      let {data} = await CrosswordsService.create();
      if (data.uuid)
        this.goto(`/my/${data.uuid}`);
    } catch (err) {
      const response = err.response?.data||{};
      this.$toast.add({severity:'error', summary: `Fehler ${response.statusCode}`, detail:`${response.message}`, life: 3000})
    }
  },
  methods:{
    goto(path="/") {
      this.$router.push(path);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
a {
  color: #42b983;
}
ol { counter-reset: item; padding:0; line-height: 1.75;
  display: flex; flex-direction: column }
ol>li { display: flex; flex-direction: row }
ol>li:before {
  content: counter(item) ".\00a0\00a0";
  counter-increment: item;
  display: inline-block;
}
</style>
