import * as Phaser from "phaser";
interface ICrossboardWord {
    "chars": string,
    "x": number,
    "y": number,
    "orientation": number,
    "hint": string
}
interface CrosswordConfiguration {
    width:number,
    height:number,
    words:Array<ICrossboardWord>,
    map:Array<Array<number>>
}
class CustomPipeline extends Phaser.Renderer.WebGL.Pipelines.SinglePipeline {
    // @ts-ignore
    constructor(game: this) {
        super({
            game: game,
            fragShader: [
                'precision lowp float;',
                'varying vec2 outTexCoord;',
                'varying vec4 outTint;',
                'uniform sampler2D uMainSampler;',
                'uniform float alpha;',
                'uniform float time;',
                'void main() {',
                'vec4 sum = vec4(0);',
                'vec2 texcoord = outTexCoord;',
                'for(int xx = -4; xx <= 4; xx++) {',
                'for(int yy = -4; yy <= 4; yy++) {',
                'float dist = sqrt(float(xx*xx) + float(yy*yy));',
                'float factor = 0.0;',
                'if (dist == 0.0) {',
                'factor = 3.0;',
                '} else {',
                'factor = 3.0/abs(float(dist));',
                '}',
                'sum += texture2D(uMainSampler, texcoord + vec2(xx, yy) * 0.02) * (abs(sin(time))+0.06);',
                '}',
                '}',
                'gl_FragColor = sum * 0.015 + texture2D(uMainSampler, texcoord)*alpha;',
                '}'
            ].join('\n')
        });
    }
}

class BoardTile extends Phaser.GameObjects.Sprite {
    solution="";
    ixn;
    constructor(config) {
        super(config.scene,config.x,config.y,"tile");
        this.solution = config.solution;
        this.ixn = config.ixn;
        this.setDepth(2);
        config.scene.add.existing(this);
    }
}
/*class BoardTile extends Phaser.GameObjects.Container {
    solution="";
    fieldSprite;
    ixn;
    constructor(config) {
        super(config.scene,config.x,config.y);
        this.fieldSprite = config.scene.add.sprite(0,0,"tile");
        this.add(this.fieldSprite);
        this.solution = config.solution;
        this.ixn = config.ixn;
        this.depth = 1;
        config.scene.add.existing(this);
    }
}*/
export default class MainScene extends Phaser.Scene
{
    ballsGroup;
    bricksGroup;
    listBalls = [];
    fullscreenButton;
    worldBounds={x:0,y:0}
    boardSize=0;
    cellDim=64+4;
    mainLight;
    activeSpotLight;
    customPipeline;
    listWords:Array<ICrossboardWord>;
    mapRef:Array<Array<number>>;
    mapFields;
    mapInput;
    mapCorrect;
    listNumbers;
    switchCounter=0;
    hintText;
    sounds;
    seletionRectanglePaddding=2;
    constructor (args) {
        super('game');
    }
    preload () {
        this.load.image('tile', this.game.config["crosswords"].plainLayout===true ?  '/templates/assets/tile-2.png' : '/templates/assets/tile.png');
        this.load.image('tileEmpty', this.game.config["crosswords"].plainLayout===true ? '/templates/assets/tile-2.png' : '/templates/assets/tile-1.png');
        this.load.image('tileCurrent', '/templates/assets/tile-2.png');
        if (this.game.config["crosswords"].plainLayout!==true) {
            this.load.audio('typing', '/templates/assets/typing.wav');
            this.load.audio('success', '/templates/assets/success.mp3');
        }
    }
    private async loadBoard(args:{uuid:string, saveState?:{[word:string]:number}}) {
        const scene = this;
        this.progressInit();

        return new Promise((resolve,reject)=>{
            if (this.game.config["crosswords"]?.data) {
                const data:CrosswordConfiguration = this.game.config["crosswords"]?.data.config;
                const fields = [];
                const words = data.words||[];
                const map = data.map||[];
                const dim = data.width;
                const values = [];
                const inputs = [];
                const numbers = [];
                for (let i=0;i<words.length;i++) {
                    const word = words[i];
                    const ixs = word.y*dim+word.x;
                    for (let n=0;n<word.chars.length;n++) {
                        values[ixs+n*(word.orientation==0 ? 1 : dim)] = word.chars[n];
                    }
                    const number = scene.add.text(0, 0, (i+1)+"", {font: "20px Arial", color: "#000000"});
                    number.setDepth(3)
                    number.setVisible(false);
                    numbers.push(number);
                }
                const maxBlock=100;
                let lastBlock = new Date().getTime();
                const nextBlock = (i) => {
                    scene.progressUpdate(i/map.length);
                    const t = new Date().getTime();
                    if (t-lastBlock>maxBlock) {
                        setTimeout(()=>{
                            lastBlock = new Date().getTime();
                            nextBlock(i)
                        })
                    }
                    else if (i<map.length) {
                        let field;
                        let input;
                        if (map[i].length>0) {
                            field = new BoardTile({scene:scene,x:0,y:0,solution:values[i],ixn:i});
                            const bounds = field.getBounds();
                            field.setInteractive(
                                new Phaser.Geom.Rectangle(0,0,68,68),
                                Phaser.Geom.Rectangle.Contains
                            ).on("pointerup",function(pointer,localX,localY,event) {
                                event.stopPropagation();
                                if (typeof this.ixn!=="undefined") {
                                    const wordRefs = map[this.ixn];
                                    const refWords = [];
                                    for (let n=0;n<wordRefs.length;n++) {
                                        const wx = wordRefs[n];
                                        refWords.push(words[wx]);
                                    }
                                    const ixRef = refWords.indexOf(scene.selectedWord)
                                    if (ixRef>-1) {
                                        if (field!=scene.currentField || wordRefs.length<2)
                                            scene.selectWord(scene.selectedWord,field);
                                        else
                                            scene.selectWord(refWords[(ixRef+1)%wordRefs.length],field);
                                    }
                                    else if (refWords.length>0) {
                                        scene.selectWord(refWords[Math.min(refWords.length-1,scene.switchCounter%2)],field);
                                        scene.switchCounter++;
                                    }
                                }

                            });
                            //field.setPipeline("Light2D");
                            field.setPipeline("MultiPipeline").setVisible(false);
                            input = scene.add.text(0, 0, "", {font: "52px Arial", color: "#331904"});
                            input.setDepth(4);
                        }
                        else {
                            field = {};
                            input = {};
                        }
                        fields.push(field);
                        inputs.push(input);
                        nextBlock(i+1);
                    }
                    else {
                        resolve({fields:fields,inputs:inputs,numbers:numbers,words:words,map:map,dim:dim});
                        scene.progressDestroy();
                    }
                };
                nextBlock(0);
            }
            else
                resolve(null);
        });
    }
    progressBar;
    progressBox;
    percentText;
    loadingText;
    progressInit() {
        this.progressBar = this.add.graphics();
        this.progressBox = this.add.graphics();
        this.progressBox.fillStyle(0x13795B, 0.8);
        this.progressBox.fillRect(240, 270, 320, 50);
        this.percentText = this.make.text({
            x: this.cameras.main.width / 2,
            y: this.cameras.main.height / 2 - 5,
            text: '0%',
            style: {
                font: '18px monospace',
                color: '#ffffff'
            }
        });
        this.percentText.setOrigin(0.5, 0.5);
        this.loadingText = this.make.text({
            x: this.cameras.main.width / 2,
            y: this.cameras.main.height / 2 - 50,
            text: 'Lade...',
            style: {
                font: '20px monospace',
                color: '#ffffff'
            }
        });
        this.loadingText.setOrigin(0.5, 0.5);
    }
    progressUpdate(f:number) {
        this.progressBar.clear();
        this.progressBar.fillStyle(0xffffff, 1);
        this.progressBar.fillRect(250, 280, 300 * f, 30);
        this.percentText.setText(Math.round(f * 100) + '%');
    }
    progressDestroy() {
        this.progressBar.destroy();
        this.progressBox.destroy();
        this.percentText.destroy();
        this.loadingText.destroy();
    }
    create () {
        this.selectedWord = null;
        this.currentField = null;
        !!this.game.config["crosswords"].onCreateStart && !!this.game.config["crosswords"].onCreateStart();
        this.loadBoard(this.game.config["crosswords"]).then((data:{fields:Array<BoardTile>,inputs:Array<Phaser.GameObjects.Text>,numbers:Array<Phaser.GameObjects.Text>,words:Array<ICrossboardWord>,map:Array<Array<number>>,dim:number})=> {
            this.boardSize = data.dim;
            this.cellDim = 64+4;
            Phaser.Actions.GridAlign(data.fields, {
                width: this.boardSize,
                height: this.boardSize,
                cellWidth: this.cellDim,
                cellHeight: this.cellDim,
                x: 0,
                y: 0
            });
            this.mapCorrect = [];
            let i=0;
            data.fields.forEach((item:any) => {
                if (item.getCenter) {
                    const pos = item.getCenter();
                    data.inputs[i].setPosition(pos.x,pos.y).setOrigin(0.5,0.5);
                    item.setVisible(true);
                }
                this.mapCorrect.push(false);
                i++;
            });
            //this.game.scale.resize(boardSize*64*(4/3),boardSize*64);
            //this.cameras.main.setSize(this.cameras.main.width,400);
            this.listWords = data.words;
            this.mapRef = data.map;
            this.mapFields = data.fields;
            this.mapInput = data.inputs;
            this.listNumbers = data.numbers;

            i=0;
            const numberPadding= this.game.config["crosswords"].plainLayout===true ? 20 : 8;
            data.words.forEach((word:ICrossboardWord)=>{
                const fields = this.getWordFields(word);
                if (fields.length) {
                    const pos = fields[0].getCenter();
                    data.numbers[i].setPosition(
                        pos.x-this.cellDim/2.0+numberPadding/2,
                        pos.y+(word.orientation===0 ? -this.cellDim/2.0+numberPadding/2 : this.cellDim/2.0-numberPadding/2)
                    ).setOrigin(0, word.orientation).setVisible(true);
                }
                i++;
            });

            const claimedWords = this.game.config["crosswords"].saveState?.claimedWords;
            for (const w in claimedWords) {
                const wx = claimedWords[w];
                if (wx<this.listWords.length && this.listWords[wx].chars==w) {
                    const word = this.listWords[wx];
                    const fields = this.getWordFields(word);
                    for (let i=0;i<fields.length;i++) {
                        this.mapInput[fields[i].ixn].setText(w[i]);
                        this.updateWordState(word,true);
                    }
                }
            }

            this.hintText = this.make.text({x:0, y:0, text:"", style:{font: "20px Arial", color: "#000000", wordWrap:{width:300}}});
            this.hintText.setDepth(5);
            this.hintText.setPadding(4).setBackgroundColor("rgba(255,255,255,0.95)").setVisible(false);

            this.worldBounds = {x:this.boardSize*this.cellDim,y:this.boardSize*this.cellDim};
            this.lights.enable();
            this.mainLight = this.lights.addLight(0,0,this.worldBounds.x).setIntensity(0);
            this.fitWorld(this.game.config["crosswords"].plainLayout===true ? 0 : 300);
        });

        this.customPipeline = this.renderer["pipelines"].add('Custom', new CustomPipeline(this));
        this.customPipeline.set1f('alpha', 1.0);

        //this.scale.on("resize", this.onResize, this);
        this.input.on("pointerup", (pointer) => {
            this.cameras.main.setSize(window.innerWidth,window.innerHeight);
            this.fitWorld();
            this.switchCounter--;
            !!this.game.config["crosswords"].onClickBoard && !!this.game.config["crosswords"].onClickBoard();
            const elm = <HTMLInputElement>document.getElementById("crosswordsInput");
            elm.blur();
            //this.cameras.main.setScroll(pointer.x,pointer.y);
            //this.game.scale.startFullscreen();
        }, this);

        // @ts-ignore
        /*this.fullscreenButton = this.add.dom(0, 0).createFromHTML("<input id='crosswordsInput' type='text' style='opacity:0'>");
        this.fullscreenButton.setOrigin(0,0);
        this.fullscreenButton.setPosition(-100,-100);*/
        const inputField = <HTMLInputElement>document.getElementById("crosswordsInput");
        if (inputField)
            inputField.addEventListener("input",(e:InputEvent) => {
                let key = e.data;
                const inputType = e.inputType;
                if (!key && inputType=="deleteContentBackward")
                    key="Backspace";
                if (key) {
                    this.handleInput({
                        key:key
                    });
                    this.initTextInput(false);
                }
            });
        /*let desktopOnlyEvents = ["Escape","Enter","ArrowLeft","ArrowRight"];
        this.input.keyboard.on("keyup",(e:KeyboardEvent)=>{
            if (desktopOnlyEvents.indexOf(e.key)>-1)
                this.handleInput(e);
        });*/

        this.sounds = {
            typing : this.cache.audio.exists("typing") ? this.sound.add("typing") : null,
            success :  this.cache.audio.exists("success") ? this.sound.add("success") : null,
        };

        this.attachHandlers();
    }
    initTextInput(reset=true) {
        const elm = <HTMLInputElement>document.getElementById("crosswordsInput");
        if (!elm)
            return;
        if (!elm.value.length)
            elm.value=" ";
        reset && elm.focus();
        setTimeout(()=>{
            elm.selectionStart = 0;
            elm.selectionEnd = 1;
            reset && elm.focus();
        });
    }
    handleInput(event) {
        const key = event.key;
        if (this.selectedWord) {
            if (key.match(/^[a-zA-Z\u00c4-\u00fc]$/)) {
                this.inputChar(key.toUpperCase());
                if (!this.nextField())
                    this.fitWorld();
            }
            else if (key=="Backspace") {
                this.inputBackspace();
            }
            else if (key=="Escape") {
                this.fitWorld();
                if (this.selectedWord)
                    this.unselectWord(this.selectedWord);
            }
            else if (key=="Enter") {
                this.fitWorld();
                if (this.selectedWord)
                    this.unselectWord(this.selectedWord);
            }
            else if (key=="ArrowLeft") {
                this.prevField();
            }
            else if (key=="ArrowRight") {
                this.nextField();
            }
        }
        if (key=="ArrowUp") {
            if (this.lastSelectedWordIndex>0)
                this.selectWord(this.listWords[this.lastSelectedWordIndex-1]);
        }
        else if (key=="ArrowDown") {
            if (this.lastSelectedWordIndex<this.listWords.length-1)
                this.selectWord(this.listWords[this.lastSelectedWordIndex+1]);
        }
    }
    inputChar(char) {
        const ix = this.currentField.ixn;
        const x = ix%this.boardSize;
        const y = Math.floor(ix/this.boardSize);
        const n = this.selectedWord.orientation==0 ? x-this.selectedWord.x : y-this.selectedWord.y;
        if (!this.mapCorrect[ix] || this.selectedWord.chars[n]==char) {
            const input = this.mapInput[ix];
            input.setText(char);
            this.sounds.typing.stop();
            this.sounds.typing.play();
        }
        else {
            /* todo: react on user trying to overwrite correct solution */
        }
    }
    inputBackspace() {
        if (this.prevField())
            this.inputChar("");
    }
    lookAtWorld(x,y,zoom:number|boolean=false,callbacks={},duration=300) {
        const cam = this.cameras.main;
        const scrollX = x-cam.width/2;//Math.min(this.worldBounds.x-cam.width/2.0,Math.max(cam.width/2.0,x)-cam.width/2.0);
        const scrollY = y-cam.height/2;//ath.min(this.worldBounds.y-cam.height/2.0,Math.max(cam.height/2.0,y))-cam.height/2.0;
        this.tweens.add({
            targets:cam,
            scrollX,
            scrollY,
            duration:duration,
            ease:"Power2",
            ...zoom!==false ? {
                zoom:zoom
            } : {},
            ...callbacks
        });
    }
    fitZoomToWorld(duration=300) {
        const cam = this.cameras.main;
        const zoom = Math.min(cam.width,cam.height)/Math.max(this.worldBounds.x+this.cellDim,this.worldBounds.y+this.cellDim);
        this.tweens.add({
            targets:cam,
            zoom:zoom,
            duration:duration,
            ease:"Power2"
        });
    }
    fitWorld(duration=300) {
        const centerX = this.boardSize*this.cellDim/2;
        const centerY = this.boardSize*this.cellDim/2;
        this.lights.setAmbientColor(0xcccccc);
        this.mainLight.x=centerX;
        this.mainLight.y=centerY;
        this.mainLight.setIntensity(3);
        this.fitZoomToWorld(duration);
        this.lookAtWorld(centerX,centerY,false,()=>{},duration);
        if (this.selectedWord) {
            this.updateWordState(this.selectedWord);
            this.unselectWord(this.selectedWord);
        }
    }
    getWordFields(word:ICrossboardWord) {
        const fields = [];
        const ixs = word.y*this.boardSize+word.x;
        for (let n=0;n<word.chars.length;n++) {
            fields.push(this.mapFields[ixs+n*(word.orientation==0 ? 1 : this.boardSize)]);
        }
        return fields;
    }
    selectedWord=null;
    lastSelectedWordIndex=-1;
    selectionRectangle:Phaser.GameObjects.Rectangle;
    removeSelectionRectangle() {
        if (this.selectionRectangle)
            this.selectionRectangle.destroy();
    }
    unselectWord(word) {
        this.removeSelectionRectangle();
        this.getWordFields(word).forEach((field) => {
            field.setTexture("tile").setPipeline("MultiPipeline");
            //field.clearTint();
        });
        this.selectedWord=null;
        this.currentField=null;
        this.hideHint();
        const btnBack = document.querySelector("#btn-back");
        btnBack.classList.remove("opacity-0");
    }
    selectWord(word:ICrossboardWord,refField:BoardTile|boolean=false,forceRedrawHint=false) {
        if (!word)
            return;
        this.initTextInput();
        this.lastSelectedWordIndex = this.listWords.indexOf(word);
        const previouslySelected = this.selectedWord;
        if (this.selectedWord && previouslySelected!=word)
            this.unselectWord(this.selectedWord);
        const fields = this.getWordFields(word);
        const i=0;
        let firstEmptyField = null;
        fields.forEach((field) => {
            const isEmpty = this.mapInput[field.ixn].text=="";
            if (!firstEmptyField && isEmpty)
                firstEmptyField=field;
            field.setTexture(isEmpty ? "tile" : "tile");
        });

        const moveToField = (word==previouslySelected && refField!==false ? refField : firstEmptyField || refField || fields.length && fields[0] || null);
        if (moveToField)
            this.moveCursorTo(moveToField);
        if (fields.length) {
            const last = fields.length-1;
            const center = {
                x:fields[0].x+(fields[last].x-fields[0].x)/2.0,
                y:fields[0].y+(fields[last].y-fields[0].y)/2.0
            }
            const dim = {x:fields[last].x-fields[0].x + this.cellDim, y:fields[last].y-fields[0].y + this.cellDim};
            const cam = this.cameras.main;
            const isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
            const isOtherMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            let height= window.innerHeight;
            if (isIpad)
                height = 2*window.innerHeight/3;
            else if (isOtherMobile)
                height = window.innerHeight/2;
            cam.setSize(window.innerWidth,height);
            const availSpace = cam[word.orientation == 0 ? "width" : "height"];
            const zoom = Math.min(availSpace/(word.orientation==0 ? dim.x : dim.y),0.95);
            this.lookAtWorld(center.x, center.y, zoom,{
                onComplete:()=>{
                    if (word!=previouslySelected || forceRedrawHint)
                        this.displayHint(word, zoom);
                }
            });
            const padding=this.seletionRectanglePaddding;
            if (this.selectionRectangle)
                this.selectionRectangle.destroy();
            this.selectionRectangle = this.add.rectangle(fields[0].x-this.cellDim/2.0-padding,fields[0].y-this.cellDim/2.0-padding,dim.x+2*padding,dim.y+2*padding,0x13795b);
            this.selectionRectangle.setOrigin(0,0);
            this.selectionRectangle.setDepth(1)
        }
        this.selectedWord = word;
        const btnBack = document.querySelector("#btn-back");
        btnBack.classList.add("opacity-0");
    }
    currentField;
    moveCursorTo(field) {
        if (this.currentField) {
            this.currentField.setTexture(this.mapInput[this.currentField.ixn].text=="" ? "tile" : "tile").setPipeline("MultiPipeline");
        }
        field.setTexture("tileCurrent").setPipeline("Custom");
        this.currentField = field;
    }
    nextField() {
        if (this.selectedWord && this.currentField) {
            const fields = this.getWordFields(this.selectedWord);
            const nextIndex = fields.indexOf(this.currentField)+1;
            const nextField = nextIndex<fields.length ? fields[nextIndex] : null;
            if (nextField)
                this.moveCursorTo(nextField);
            return nextField;
        }
        return null;
    }
    prevField() {
        if (this.selectedWord && this.currentField) {
            const fields = this.getWordFields(this.selectedWord);
            const prevIndex = fields.indexOf(this.currentField)-1;
            const prevField = prevIndex>=0 ? fields[prevIndex] : null;
            if (prevField)
                this.moveCursorTo(prevField);
            return prevField;
        }
        return null;
    }
    displayHint(word:ICrossboardWord, zoom=1) {
        this.hintText.setText(word.hint);
        this.hintText.setVisible(true);
        const fields = this.getWordFields(word);
        if (fields.length>0) {
            const pos = fields[0].getCenter();
            const targetPos = {x:pos.x,y:pos.y};
            let revealPos = {x:pos.x,y:pos.y};
            const cam = this.cameras.main;
            const view = cam.worldView;
            let targetWidth;
            let fixedWidth;
            if (word.orientation==0) {
                if (pos.y-view.y<view.height/2.0) {
                    this.hintText.setOrigin(0,0);
                    targetPos.x = pos.x-this.cellDim/2.0-this.seletionRectanglePaddding;
                    targetPos.y = pos.y+this.cellDim/2.0;
                    revealPos = {x:targetPos.x,y:targetPos.y+this.cellDim};
                }
                else {
                    this.hintText.setOrigin(0,1);
                    targetPos.x = pos.x-this.cellDim/2.0-this.seletionRectanglePaddding;
                    targetPos.y = pos.y-this.cellDim/2.0;
                    revealPos = {x:targetPos.x,y:targetPos.y-this.cellDim};
                }
                targetWidth = word.chars.length*this.cellDim+this.seletionRectanglePaddding*2;
                fixedWidth = targetWidth;
            }
            else {
                if (pos.x-view.x<view.width/2.0) {
                    this.hintText.setOrigin(0,0);
                    targetPos.x = pos.x+this.cellDim/2.0;
                    targetPos.y = pos.y-this.cellDim/2.0;
                    targetWidth = view.width-(pos.x-view.x+this.cellDim/2.0);
                    revealPos = {x:targetPos.x+this.cellDim,y:targetPos.y};
                }
                else {
                    this.hintText.setOrigin(1,0);
                    targetPos.x = pos.x-this.cellDim/2.0;
                    targetPos.y = pos.y-this.cellDim/2.0;
                    targetWidth = pos.x-view.x-this.cellDim/2.0;
                    revealPos = {x:targetPos.x-this.cellDim,y:targetPos.y};
                }
                fixedWidth = 0;
            }
            this.hintText.setStyle({fontSize:`${Math.round(20.0/zoom)}px`, wordWrap:{width:targetWidth}, fixedWidth:fixedWidth});
            this.hintText.alpha=0;
            this.hintText.setPosition(revealPos.x,revealPos.y);
            this.tweens.add({
                targets: this.hintText,
                x: targetPos.x,
                y: targetPos.y,
                alpha:1,
                duration: 300,
                ease: "Power2"
            });
        }
    }
    hideHint() {
        this.hintText.setVisible(false);
    }
    getWordInput(word:ICrossboardWord) {
        const fields = this.getWordFields(word);
        let str="";
        fields.forEach((field)=>{
            str+=this.mapInput[field.ixn].text;
        });
        return str;
    }
    correctWords={};
    updateWordState(word:ICrossboardWord,silent=false) {
        const input = this.getWordInput(word);
        const correct = input==word.chars;
        const fields = this.getWordFields(word);
        let changed = false;
        fields.forEach((field)=>{
            if (correct || this.mapRef[field.ixn].length==1) {
                this.mapInput[field.ixn].setColor(correct ? "#13795B": "#331904");
                if (correct && !this.mapCorrect[field.ixn] || !correct && this.mapCorrect[field.ixn])
                    changed = true;
                this.mapCorrect[field.ixn] = correct;
            }
        });
        if (correct && changed) {
            this.correctWords[word.chars] = this.listWords.indexOf(word);
            /* todo: saveState */
            this.game.config["onSaveState"] && this.game.config["onSaveState"](this.game.config["crosswords"].uuid,this.correctWords);
            if (!silent)
                this.sounds.success.play();
        }
    }
    attachHandlers() {
        this.game.events.off("onResize").on("onResize",()=>{
            this.onResize();
        });
    }
    onResize() {
        this.scale.resize(window.innerWidth/this.game.config.zoom,window.innerHeight/this.game.config.zoom);
        if (this.selectedWord)
            this.selectWord(this.selectedWord,this.currentField,true);
        else
            this.fitWorld();
    }
    ftime=0;
    update() {
        const cursorKeys = this.input.keyboard.createCursorKeys();
        if (cursorKeys.up.isDown) {
            /**/
        } else if (cursorKeys.down.isDown) {
            /**/
        }
        else if (cursorKeys.right.isDown) {
            /**/
        } else if (cursorKeys.left.isDown) {
            /**/
        }

        this.customPipeline.set1f('time', this.ftime);
        this.ftime+=0.05;
    }
}