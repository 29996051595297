<template>
  <div :class="`flex-grow-1 flex flex-column align-items-center`">
    <div v-if="isGuest" class="p-2 mx-2 mt-2 mb-0 bg-white-alpha-80 relative flex flex-column">
      <div><i class="pi pi-user text-xl text-blue-500 relative" style="top: 3px;"></i> Logged in as {{profile.username}}</div>
      <a class="text-blue-500 inline-flex flex-row justify-content-end mt-1 no-underline" href="/register"><i class="pi pi-caret-right"></i> Register</a>
    </div>
    <div :class="`p-2 mx-2 mb-0 relative flex flex-column w-full ${loading ? 'opacity-0' : 'opacity-100'}`">
      <div class="w-full bg-white-alpha-80 p-2">
        <div v-if="crossword.data?.settings" class="flex flex-row justify-content-between">
          <div class="flex flex-column" style="line-height:125%">
            <div>Thema: {{crossword.data?.settings?.topic||'-'}}</div>
            <div>Wörter: {{crossword.data?.config?.words?.length}}</div>
            <div>Schwierigkeitsgrad: {{crossword.data?.settings?.level||'-'}}</div>
            <div>AI Modell: {{crossword.data?.settings?.model||'-'}}</div>
          </div>
          <div v-if="gameFinished"><i class="pi pi-check text-2xl text-green-600"/></div>
          <div v-else-if="gameProgress>0" class="flex flex-row justify-content-center"><i class="pi pi-clock"></i><div class="ml-1">{{gameProgress}}/{{gameTarget}}</div></div>
        </div>
        <div v-else-if="!loading" class="p-fluid grid formgrid">
          <div class="field col-12">
            <label>Thema</label>
            <InputText type="text" v-model="inputTopic" class="flex-grow-1" placeholder="z.B. Das tapfere Schneiderlein" autofocus/>
            <label class="mt-3">Grösse</label>
            <Dropdown v-model="inputCount" :options="listSizes" optionLabel="name" optionValue="value" placeholder="Wählen" />
            <label class="mt-3">Schwierigkeitsgrad</label>
            <Dropdown v-model="inputLevel" :options="listLevels" optionLabel="value" optionValue="value" placeholder="Wählen" />
            <label class="mt-3">AI Modell</label>
            <Dropdown v-model="inputModel" :options="listModels" optionLabel="name" optionValue="value" placeholder="Wählen" />
          </div>
        </div>
        <div class="flex justify-content-between" v-if="crossword.data?.config">
          <div class="flex flex-row mt-3" v-show="!loading" >
            <Button label="Lösungen" icon="pi pi-question-circle" @click="showSolution=true" class="p-button-secondary">
              <div class="flex flex-row align-items-center">
                <i class="pi pi-question-circle text-2xl"></i>
                <div class="hidden sm:flex flex-column align-items-start ml-2">
                  <div>Lösungen</div>
                  <small>anzeigen</small>
                </div>
              </div>
            </Button>
            <Button label="Starten" icon="pi pi-play" @click="download" class="ml-2">
              <div class="flex flex-row align-items-center">
                <i :class="'pi text-2xl '+(downloading ? 'pi-spinner pi-spin' : 'pi-file-pdf')"></i>
                <div class="hidden sm:flex flex-column align-items-start ml-2">
                  <div>Kreuzworträtsel</div>
                  <small>drucken</small>
                </div>
              </div>
            </Button>
          </div>
          <div class="mt-3 flex flex-row">
            <Button icon="pi pi-question-circle" @click="copyGameUrlToClipboard" class="p-button-secondary">
              <div class="flex flex-row align-items-center">
                <i class="pi pi-share-alt text-2xl"></i>
              </div>
            </Button>
            <Button v-show="!loading" label="Starten" icon="pi pi-play" @click="startGame" class="ml-2">
              <div class="flex flex-row align-items-center">
                <div class="flex flex-column align-items-start">
                  <div>Spiel</div>
                  <small>{{gameStarted ? (gameFinished ? 'ansehen' : 'fortsetzen') : 'starten'}}</small>
                </div>
                <i class="pi pi-play text-2xl ml-1"></i>
              </div>
            </Button>
          </div>
        </div>
        <div v-else-if="!loading">
          <Button label="Starten" icon="pi pi-play" @click="requestGenerateCrossword" :disabled="generating || !inputTopic">
            <div class="flex flex-row align-items-center">
              <i :class="'pi text-2xl '+(generating ? 'pi-spinner pi-spin' : 'pi-play')"></i>
              <div class="flex flex-column align-items-start ml-2">
                <div>Kreuzworträtsel</div>
                <small>erzeugen</small>
              </div>
            </div>
          </Button>
        </div>
      </div>
    </div>
    <Dialog v-model:visible="showSolution" modal dismissable-mask header="Lösungen" :style="{ width: '50vw' }" :breakpoints="{ '960px': '75vw', '500px': '90vw', '400px': '100vw' }">
      <h3 class="m-0"><b>HORIZONTAL</b></h3>
      <div v-for="w of solution.h" :key="w.ix" class="flex flex-row">
        <div class="w-1rem"><sup>{{w.ix+1}}</sup></div><div class="ml-1">{{w.chars}}</div>
      </div>
      <h3 class="mt-3"><b>VERTIKAL</b></h3>
      <div v-for="w of solution.v" :key="w.ix" class="flex flex-row">
        <div class="w-1rem"><sup>{{w.ix+1}}</sup></div><div class="ml-1">{{w.chars}}</div>
      </div>
    </Dialog>
    <Dialog v-model:visible="showSocialShare" modal dismissable-mask header="Spiel teilen">
      <div class="flex flex-row">
        <i class="pi pi-whatsapp text-3xl"/>
        <i class="pi pi-facebook text-3xl"/>
      </div>
    </Dialog>
  </div>
</template>

<script>

import AuthGuestView from "@/components/AuthGuestView.vue";
import CrosswordsService from "@/crosswords-service";
import {singletons} from "../../shared";
import gamePrinterService from "@/game-printer-service";

export default {
  name: 'MyCrossword',
  extends: AuthGuestView,
  props: {
    msg: String,
    uuid: String
  },
  data() {
    return {
      profile:{},
      isGuest:false,
      crossword:{},
      loading:true,
      showSolution:false,
      solution:{},
      inputTopic:"",
      inputCount:10,
      inputLevel:"Primarschule",
      inputModel:"gpt-3.5-turbo",
      listSizes:[{name:"Klein",value:10}, {name:"Mittel",value:25}, {name:"Gross",value:50}],
      listLevels:[{value:"Vorschule"}, {value:"Primarschule"}, {value:"Oberstufe"}, {value:"Hochschule"}, {value:"Kreuzworträtsel-Experte"}],
      listModels:[{name:"gpt-3.5-turbo",value:"gpt-3.5-turbo"}],
      gameStarted:false,
      gameFinished:false,
      gameProgress:0,
      gameTarget:0,
      showSocialShare:false,
      generating:false,
      downloading:false
    }
  },
  async created() {
    this.profile = await this.deferred;

    // if we don't have a login/profile at this point, this means guest creation is disallowed on the server (so redirect to /login)
    if (!this.profile)
      this.$router.push("/login");
    else {
      this.isGuest = this.profile.username?.match(/^guest_/);
      await this.loadCrossword();
      this.solution = this.getSolution();
      this.generating = this.crossword.data?.settings && !this.crossword.data?.config;
      if (typeof this.crossword.data?.error!=="undefined") {
        this.$toast.add({severity:'error', summary: 'Fehler', detail:this.crossword.data?.error, life: 5000})
        this.generating = false;
      }
      if (this.crossword.data?.settings) {
        this.inputTopic = this.crossword.data?.settings.topic;
        this.inputCount = this.crossword.data?.settings.count;
        this.inputLevel = this.crossword.data?.settings.level;
        this.inputModel = this.crossword.data?.settings.model;
      }
      this.loading = false;
      this.attachWebsocketHandlers();
    }
  },
  methods:{
    attachWebsocketHandlers() {
      for (let node of Object.keys(singletons.sockets)) {
        singletons.sockets[node].off('onGenerateDone').on('onGenerateDone', async (data) => {
          console.log("onGenerateDone",data);
          await this.loadCrossword();
          this.solution = this.getSolution();
          this.initGameStats();
        });
        singletons.sockets[node].off('onGenerateError').on('onGenerateError', async ({error}) => {
          console.log(error);
          this.generating = false;
          this.$toast.add({severity:'error', summary: 'Fehler', detail:error, life: 5000})
        });
      }
    },
    goto(path="/") {
      this.$router.push(path);
    },
    getDifficultyNumeric(str) {
      return str==="hard" ? 2 : (str==="medium" ? 1 : 0);
    },
    async loadCrossword(uuid=this.uuid) {
      let {data:crossword} = await CrosswordsService.get(uuid);
      try {
        crossword.data = JSON.parse(crossword.data);
      } catch (err) {
        crossword.data = {};
      }
      try {
        crossword.saveState = JSON.parse(crossword.saveState);
      } catch (err) {
        crossword.saveState = {};
      }
      this.crossword = crossword;
      this.initGameStats();
      return this.crossword;
    },
    async generateCrossword(id=this.crossword?.id) {
      await CrosswordsService.generateFull({id,count:this.inputCount,topic:this.inputTopic,level:this.inputLevel,model:this.inputModel});
    },
    getGameUrl() {
      return `/game/${this.uuid}`
    },
    startGame() {
      this.goto(this.getGameUrl());
    },
    getSolution() {
      if (this.crossword.data?.config) {
        let words = [...this.crossword.data.config.words];
        words = words.map((w,ix)=>({...w,ix}));
        let h = words.filter(w=>w.orientation===0);
        let v = words.filter(w=>w.orientation===1);
        return {h,v};
      }
      return {};
    },
    async requestGenerateCrossword() {
      this.generating = true;
      await this.generateCrossword();
    },
    initGameStats(crossword=this.crossword) {
      let listWords = (crossword.data.config?.words||[]).map(w=>w.chars);
      let claimedWords = Object.keys(crossword.saveState?.claimedWords||{}).filter(w=>listWords.indexOf(w)>-1);
      this.gameTarget = (crossword.data.config?.words||[]).length;
      this.gameStarted = claimedWords.length>0;
      this.gameProgress = claimedWords.length;
      this.gameFinished = this.gameTarget>0 && this.gameProgress>=this.gameTarget;
    },
    async copyGameUrlToClipboard() {
      try {
        await navigator.clipboard.writeText(`${window.location.origin}${this.getGameUrl()}`);
        this.$toast.add({severity:'success', summary: 'Teilen', detail:"Link zum Spiel wurde in Zwischenablage kopiert", life: 5000})
      } catch (err) {
        this.$toast.add({severity:'error', summary: 'Teilen', detail:err, life: 5000})
      }
    },
    async download() {
      this.downloading = true;
      let response = await gamePrinterService.get(this.uuid);
      let blobUrl = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = this.uuid;
      document.body.appendChild(link);
      link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          })
      );
      document.body.removeChild(link);
      this.downloading = false;
    },
  },
  computed:{
    getWords() {
      if (this.crossword.data?.config) {
        return this.crossword.data.config.words.map(item=>`<div class="flex flex-row"><i class='pi pi-check'></i>&nbsp;${item.chars}</div>`).join("");
      }
      return '';
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
a {
  color: #42b983;
}
ol { counter-reset: item; padding:0; line-height: 1.75;
  display: flex; flex-direction: column }
ol>li { display: flex; flex-direction: row }
ol>li:before {
  content: counter(item) ".\00a0\00a0";
  counter-increment: item;
  display: inline-block;
}
</style>
