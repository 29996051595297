<template>
  <div>
    <div id="btn-back" class="tap-highlight-none select-none absolute text-8xl text-white hover:text-gray-700 transition-all cursor-pointer p-1 m-0 z-2" style="line-height:0.5"
         @click="goBack">&#8249;</div>
    <div id="btn-keyboard" @click="toggleInput" v-show="isMobile">
      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M64 112c-8.8 0-16 7.2-16 16V384c0 8.8 7.2 16 16 16H512c8.8 0 16-7.2 16-16V128c0-8.8-7.2-16-16-16H64zM0 128C0 92.7 28.7 64 64 64H512c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zM176 320H400c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V336c0-8.8 7.2-16 16-16zm-72-72c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H120c-8.8 0-16-7.2-16-16V248zm16-96h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H120c-8.8 0-16-7.2-16-16V168c0-8.8 7.2-16 16-16zm64 96c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H200c-8.8 0-16-7.2-16-16V248zm16-96h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H200c-8.8 0-16-7.2-16-16V168c0-8.8 7.2-16 16-16zm64 96c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H280c-8.8 0-16-7.2-16-16V248zm16-96h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H280c-8.8 0-16-7.2-16-16V168c0-8.8 7.2-16 16-16zm64 96c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H360c-8.8 0-16-7.2-16-16V248zm16-96h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H360c-8.8 0-16-7.2-16-16V168c0-8.8 7.2-16 16-16zm64 96c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H440c-8.8 0-16-7.2-16-16V248zm16-96h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H440c-8.8 0-16-7.2-16-16V168c0-8.8 7.2-16 16-16z"/></svg>
    </div>
    <input id="crosswordsInput" class="absolute" style="opacity:0; top:-1000px">
  </div>
</template>

<script>
import * as Phaser from "phaser";
import MainScene from "@/game";
import AuthGuestView from "@/components/AuthGuestView.vue";
import CrosswordsService from "@/crosswords-service";
import GamesService from "@/games-service";
import singletons from "@/singletons"
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Game',
  extends: AuthGuestView,
  props: {
    uuid: String
  },
  data() {
    return {
      phaserGame:null,
      debounceResize:50,
      handleResize:null,
      isMobile:false,
      isIpad:false,
      plainLayout: false,
      phaserRender: Phaser.AUTO,
      phaserScaleMode: Phaser.Scale.NONE,
      backgroundColor: "000000",
      crossword:{},
      phaserContainerId:"phaser-container"
    }
  },
  async mounted() {
    await this.onMounted();
  },
  methods:{
    async onMounted() {
      this.profile = await this.deferred;
      this.isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
      this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || this.isIpad;

      // if we don't have a login/profile at this point, this means guest creation is disallowed on the server (so redirect to /login)
      if (!this.profile)
        this.$router.push("/login");
      else {
        window.removeEventListener("resize",this.onPhaserResize)
        window.addEventListener("resize",this.onPhaserResize)

        this.crossword = await this.loadCrossword(this.uuid);
        console.log(this.crossword);
        const config = {
          type: this.phaserRender,
          parent: this.phaserContainerId,
          width: window.innerWidth,
          height: window.innerHeight,
          scale: {
            mode: this.phaserScaleMode,
          },
          backgroundColor:this.backgroundColor,
          scene: [ MainScene ]
        };
        if (singletons.phaserGame) {
          let game = singletons.phaserGame;
          game["config"] = {
            ...game.config,
            ...{
              crosswords:{
                ...this.crossword,
                plainLayout:this.plainLayout
              },
              onSaveState:(uuid,claimedWords) => {
                GamesService.save({uuid,saveState:{claimedWords}})
              }
            }
          }
          game.scene.getScene("game").scene.restart();
        }
        else {
          let game = new Phaser.Game(config);
          game["config"] = {
            ...game.config,
            ...{
              crosswords:{
                ...this.crossword,
                plainLayout:this.plainLayout
              },
              onSaveState:(uuid,claimedWords) => {
                GamesService.save({uuid,saveState:{claimedWords}})
              }
            }
          }
          singletons.phaserGame = game;
        }
      }
    },
    onPhaserResize(){
      //this.phaserGame.events.emit("onResize");
      clearTimeout(this.handleResize);
      this.handleResize = setTimeout(()=>{
        if (singletons.phaserGame)
          singletons.phaserGame.events.emit("onResize");
      },this.debounceResize);
    },
    async loadCrossword(uuid=this.uuid) {
      let {data:crossword} = await CrosswordsService.get(uuid);
      try {
        crossword.data = JSON.parse(crossword.data);
      } catch (err) {
        crossword.data = {};
      }
      try {
        crossword.saveState = JSON.parse(crossword.saveState);
      } catch (err) {
        crossword.saveState = {};
      }
      return crossword;
    },
    goBack() {
      if (!(this.$router.options?.history?.state?.back||"").match(/random/))
        this.$router.back();
      else
        this.$router.go(-2);
    },
    toggleInput() {
      let input = document.getElementById("crosswordsInput");
      if (input===document.activeElement)
        input.blur();
      else
        input.focus();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
a {
  color: #42b983;
}
#phaser-container {
  position: absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
}
#phaser-container>canvas {
  position: absolute;
  width: 100%;
  height: 100%;
}

</style>
