import CrudService from "./crud-service";
import http from "@/http-auth";

class CrosswordsService extends CrudService {
  generate({id,words,settings}) {
    return http.post(`/${this.apiName}/generate`, {id,words,settings});
  }
  words({count,topic,level,model}) {
    return http.post(`/${this.apiName}/words`, {count,topic,level,model});
  }
  generateFull({id,count,topic,level,model}) {
    return http.post(`/${this.apiName}/generate-full`, {id,count,topic,level,model});
  }
  getRandom() {
    return http.get(`/${this.apiName}/random`);
  }
}
export default new CrosswordsService("crosswords");