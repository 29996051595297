<template>
  <form v-if="ensured" class="flex-grow-1 flex flex-colum justify-content-center align-items-center bg-login" ref="loginForm" autocomplete="on" v-on:submit.prevent>
    <div class="p-4 border-black-alpha-50 w-full lg:w-6 bg-white-alpha-90 shadow-2 relative">
      <div class="absolute left-0 top-0 m-2" v-if="sso.name">
        <a class="text-sm font-medium no-underline text-blue-500 cursor-pointer" :href="sso.url">Einloggen mit {{sso.name}}</a>
      </div>
      <div class="absolute right-0 top-0 m-2">
        <a class="text-sm font-medium no-underline text-blue-500 cursor-pointer" href="/register"> Sie haben noch keinen Account?</a>
      </div>
      <div class="text-center mb-3 relative">
        <div class="flex flex-row justify-content-center align-items-center mb-1">
          <div class="text-900 text-3xl font-medium flex align-items-end line-height-1" @click="goto()">Crosswords AI</div>
        </div>
        <span class="font-medium line-height-3">Bei Ihrem Konto anmelden</span>
      </div>
      <div>
        <label for="username" class="block text-900 font-medium mb-2">Benutzername</label>
        <InputText id="username" type="text" class="w-full mb-3" v-model="username" autocomplete="username" name="username" required />

        <label for="password" class="block text-900 font-medium mb-2">Passwort</label>
        <InputText id="password" type="password" class="w-full mb-3" v-model="password" autocomplete="password" name="password" required v-on:keyup.enter="login" />

        <div class="flex align-items-center justify-content-between">
          <a class="font-medium no-underline text-blue-500 cursor-pointer" href="/reset">Passwort vergessen?</a>
          <Button label="Anmelden" icon="pi pi-sign-in" class="flex-shrink-0" @click="login"></Button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>

export default {
  name: 'LoginView',
  components: {
  },
  data() {
    return {
      username:"",
      password:"",
      target:"",
      redirect:"",
      ensured:false,
      sso:{}
    }
  },
  async created() {
    this.target = this.$route.query?.q;
    this.redirect = this.$route.query?.r;
    try {
      if (await this.authService.ensureAuth()) {
        if (this.redirect) {
          /*const link = document.createElement('a');
          link.href = this.redirect;
          link.dispatchEvent(new MouseEvent('click'));*/
          let { accessToken } = this.authService.getLocalAuth();
          window.location.href = `${this.redirect}/?q=${accessToken}`;
        }
        else
          this.goto(this.target);
      }
      else
        this.ensured = true;
    }
    catch (err) {
      this.$toast.add({severity:'error', summary: 'Fehler', detail:err, life: 3000})
      this.ensured = true;
    }
    if (process.env.VUE_APP_SSO_ORGANIZATION_NAME && process.env.VUE_APP_SSO_ORGANIZATION_URL)
      this.sso = {
        name:process.env.VUE_APP_SSO_ORGANIZATION_NAME,
        url:process.env.VUE_APP_SSO_ORGANIZATION_URL
      }
  },
  methods: {
    goto(path="/") {
      this.$router.push(path);
    },
    clear() {
      this.username="";
      this.password="";
    },
    async login() {
      if (this.username && this.password) {
        try {
          await this.authService.login({username:this.username,password:this.password});
          await this.authService.ensureAuth();
          if (this.redirect) {
            let { accessToken } = this.authService.getLocalAuth();
            window.location.href = `${this.redirect}/?q=${accessToken}`;
          }
          else
            this.goto(this.target);
        } catch (err) {
          this.$toast.add({severity:'error', summary: 'Login Fehler', detail:err.response ? `${err.response.status} ${err.response.statusText}` : err, life: 3000})
          this.clear();
        }
      }
    },
  }
}
</script>