import {createRouter, createWebHashHistory, createWebHistory} from "vue-router";
import LoginView from "@/components/LoginView";
import HomeView from "@/components/HomeView";
import UsersView from "@/components/UsersView";
import SystemView from "@/components/SystemView";
import UserView from "@/components/UserView";
import SettingsView from "@/components/SettingsView";
import ResetView from "@/components/ResetView";
import ResetRequestView from "@/components/ResetRequestView";
import PasswordExpiredView from "@/components/PasswordExpiredView";
import ConfirmationView from "@/components/ConfirmationView.vue";
import ConfirmationRequestView from "@/components/ConfirmationRequestView.vue";
import LogoutView from "@/components/LogoutView.vue";
import SSOView from "@/components/SSOView.vue";
import MyCrosswords from "@/components/MyCrosswords.vue";
import Game from "@/components/Game.vue";
import CreateCrossword from "@/components/CreateCrossword.vue";
import MyCrossword from "@/components/MyCrossword.vue";
import RandomGame from "@/components/RandomGame.vue";
import RegistrationGuestTransferView from "@/components/RegistrationGuestTransferView.vue";
import GamePrinter from "@/components/GamePrinter.vue";

const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        component: HomeView
    },
    {
        path: '/my',
        component: MyCrosswords
    },
    {
        path: '/my/:uuid',
        component: MyCrossword,
        props:true
    },
    {
        path: '/create',
        component: CreateCrossword,
    },
    {
        path: '/game/:uuid',
        component: Game,
        props: true
    },
    {
        path: '/print-game/:uuid',
        component: GamePrinter,
        props: true
    },
    {
        path: '/random-game',
        component: RandomGame
    },
    {
        path: '/system',
        component: SystemView
    },
    {
        path: '/system/users',
        component: UsersView
    },
    {
        path: '/system/users/:id',
        component: UserView,
        props: true
    },
    {
        path: '/system/config',
        component: SettingsView
    },
    {
        path: '/login',
        component: LoginView
    },
    {
        path: '/logout',
        component: LogoutView
    },
    {
        path: '/reset',
        component: ResetRequestView
    },
    {
        path: '/reset/:uuid',
        component: ResetView,
        props: true
    },
    {
        path: '/password-expired/',
        component: PasswordExpiredView,
    },
    {
        path: '/confirmation',
        component: ConfirmationRequestView,
    },
    {
        path: '/confirmation/:uuid',
        component: ConfirmationView,
        props: true
    },
    {
        path: '/register',
        component: RegistrationGuestTransferView
    },
    {
      path: '/sso/:accessToken/:refreshToken',
      component: SSOView,
      props:true
    }
]

const router = createRouter({
    history: process.env.IS_ELECTRON ? createWebHashHistory() : createWebHistory(process.env.BASE_URL),
    routes
})

export default router
